import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
import versions from '../../../gen-src/versions.json';
export const pageTitle = "Kotlin integration";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "kotlin-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#kotlin-integration",
        "aria-label": "kotlin integration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kotlin integration`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#coroutines-support-for-annotated-services"
          }}>{`Coroutines support for annotated services`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#coroutine-context-and-dispatcher"
            }}>{`Coroutine context and dispatcher`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#coroutine-dispatcher"
            }}>{`Coroutine dispatcher`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#customizing-coroutinecontext-for-annotated-services"
            }}>{`Customizing CoroutineContext for annotated services`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#coroutines-support-for-typerestclient"
          }}>{`Coroutines support for type://RestClient`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#coroutines-support-for-grpc-kotlin"
          }}>{`Coroutines support for gRPC-Kotlin`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#running-a-grpc-kotlin-service"
            }}>{`Running a gRPC-Kotlin service`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#calling-a-grpc-kotlin-service"
            }}>{`Calling a gRPC-Kotlin service`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#customizing-coroutinecontext-for-grpc-kotlin-services"
            }}>{`Customizing CoroutineContext for gRPC-Kotlin services`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "coroutines-support-for-annotated-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#coroutines-support-for-annotated-services",
        "aria-label": "coroutines support for annotated services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coroutines support for annotated services`}</h2>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria/tree/main/examples/annotated-http-service-kotlin"
        }}>{`annotated-http-service-kotlin`}</a>{` to check out a fully working example.`}</p>
    </Tip>
    <p>{`You can implement annotated services with `}<a parentName="p" {...{
        "href": "https://kotlinlang.org/docs/reference/coroutines-overview.html"
      }}>{`Kotlin coroutines`}</a>{`
by adding the following dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-kotlin'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, define the suspending function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`class MyAnnotatedService {
    @Get("/users/{name}")
    suspend fun getUserToken(@Param("name") name: String): String {
        val user = myReactiveRepository.findByName(name).await()
        return user.token
    }
}
`}</code></pre>
    <p>{`Note that you can omit the value of `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` if you compiled your Kotlin code with `}<a parentName="p" {...{
        "href": "https://kotlinlang.org/docs/reference/compiler-reference.html#-java-parameters"
      }}><inlineCode parentName="a">{`java-parameters`}</inlineCode></a>{` option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`class MyAnnotatedService {
    @Get("/users/{name}")
    suspend fun getUserToken(@Param name: String): String { ... }
}
`}</code></pre>
    <p>{`Data classes are also supported for JSON requests and responses:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`data class User(val name: String, val token: String)
data class Result(val status: Int, val message: String)

class MyRestService {

    @ConsumesJson
    @ProducesJson
    @Post("/users")
    suspend fun createUser(user: User): Result {
        return myReactiveRepository.save(user).await()
    }
}
`}</code></pre>
    <h3 {...{
      "id": "coroutine-context-and-dispatcher",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coroutine-context-and-dispatcher",
        "aria-label": "coroutine context and dispatcher permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coroutine context and dispatcher`}</h3>
    <p><a parentName="p" {...{
        "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
      }}>{`type://ServiceRequestContext`}</a>{` is injected to the default `}<inlineCode parentName="p">{`CoroutineContext`}</inlineCode>{` of a `}<inlineCode parentName="p">{`suspend`}</inlineCode>{` function,
so you can access `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
      }}>{`type://ServiceRequestContext`}</a>{` from a `}<inlineCode parentName="p">{`suspend`}</inlineCode>{` function with `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext.current()/"
      }}>{`type://ServiceRequestContext.current()/`}</a>{`
and the context is inherits to child contexts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`class MyContextAwareService {
    suspend fun contextPropagation(@Param name: String): String {
        // Make sure that current thread is request context aware
        assert(ServiceRequestContext.current() != null)

        return withContext(myDispatcher) {
            // Make sure that the ServiceRequestContext is propagated to other CoroutineContext which is needed 
            // for logging, tracing.
            assert(ServiceRequestContext.current() != null)
            ...
        }
    }
}
`}</code></pre>
    <h3 {...{
      "id": "coroutine-dispatcher",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coroutine-dispatcher",
        "aria-label": "coroutine dispatcher permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coroutine dispatcher`}</h3>
    <p>{`By default, Armeria uses a context-aware event loop as a
`}<a parentName="p" {...{
        "href": "https://kotlin.github.io/kotlinx.coroutines/kotlinx-coroutines-core/kotlinx.coroutines/-coroutine-dispatcher/"
      }}>{`coroutine dispatcher`}</a>{` to run `}<inlineCode parentName="p">{`suspend`}</inlineCode>{` functions.
A context-aware blocking executor is used as a dispatcher if you annotate a service method with
`}<a parentName="p" {...{
        "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
      }}>{`type://@Blocking`}</a>{` or enable `}<a parentName="p" {...{
        "href": "type://AnnotatedServiceBindingBuilder#useBlockingTaskExecutor(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/AnnotatedServiceBindingBuilder.html#useBlockingTaskExecutor(boolean)"
      }}>{`type://AnnotatedServiceBindingBuilder#useBlockingTaskExecutor(boolean)`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`serverBuilder
    .annotatedService()
    // Enable \`useBlockingTaskExecutor\`.
    .useBlockingTaskExecutor(true)
    .build(MyAnnotatedService())

// or use @Blocking annotation:

import com.linecorp.armeria.server.annotation.Blocking

class MyAnnotatedService {
    @Blocking
    @Get("/blocking/users/{name}")
    suspend fun getUserTokenBlocking(@Param("name") name: String): String {
        val id = myReactiveClient.getId(name).await()
        val user = myBlockingRepository.findById(id)
        return user.token
    }
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`If you use `}<a parentName="p" {...{
          "href": "/docs/advanced-logging"
        }}>{`Logback integration`}</a>{` or `}<a parentName="p" {...{
          "href": "/docs/advanced-zipkin"
        }}>{`Zipkin integration`}</a>{`,
coroutines should be executed in a thread that is aware of `}<a parentName="p" {...{
          "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
        }}>{`type://RequestContext`}</a>{`.`}</p>
    </Tip>
    <h3 {...{
      "id": "customizing-coroutinecontext-for-annotated-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#customizing-coroutinecontext-for-annotated-services",
        "aria-label": "customizing coroutinecontext for annotated services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing `}<inlineCode parentName="h3">{`CoroutineContext`}</inlineCode>{` for annotated services`}</h3>
    <p>{`Armeria provides a way to configure a coroutine context of annotated service methods
using `}<a parentName="p" {...{
        "href": "type://CoroutineContextService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/kotlin/CoroutineContextService.html"
      }}>{`type://CoroutineContextService`}</a>{` decorator.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`import com.linecorp.armeria.server.kotlin.CoroutineContextService

serverBuilder
    .annotatedService()
    .decorator(CoroutineContextService.newDecorator { ctx ->
        CoroutineName(ctx.config().defaultServiceNaming().serviceName(ctx) ?: "name")
    })
    .build(MyAnnotatedService())
`}</code></pre>
    <h2 {...{
      "id": "coroutines-support-for-typerestclient",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#coroutines-support-for-typerestclient",
        "aria-label": "coroutines support for typerestclient permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coroutines support for `}<a parentName="h2" {...{
        "href": "type://RestClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RestClient.html"
      }}>{`type://RestClient`}</a></h2>
    <p>{`Armeria provides extension methods to convert `}<a parentName="p" {...{
        "href": "type://RestClientPreparation#execute(Class):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RestClientPreparation.html#execute(java.lang.Class)"
      }}>{`type://RestClientPreparation#execute(Class)`}</a>{` to a `}<inlineCode parentName="p">{`suspend`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val client = RestClient.of("https://armeria.dev")
// In a coroutine scope.
val response: ResponseEntity<RestResponse> =
   client.get("/api/v1/users/{id}")
         .pathParam("id", "Armeria")
         .header(HttpHeaderNames.AUTHORIZATION, "...")
         // The suspend function executes the request and
         // returns the response.
         .execute<RestResponse>()
`}</code></pre>
    <h2 {...{
      "id": "coroutines-support-for-grpc-kotlin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#coroutines-support-for-grpc-kotlin",
        "aria-label": "coroutines support for grpc kotlin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coroutines support for `}<a parentName="h2" {...{
        "href": "https://github.com/grpc/grpc-kotlin"
      }}>{`gRPC-Kotlin`}</a></h2>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria/tree/main/examples/grpc-kotlin"
        }}>{`grpc-kotlin`}</a>{` to check out a fully working example.`}</p>
    </Tip>
    <p>{`To use gRPC-Kotlin with Armeria, add the following dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-grpc-kotlin'
    }]} mdxType="RequiredDependencies" />
    <h3 {...{
      "id": "running-a-grpc-kotlin-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#running-a-grpc-kotlin-service",
        "aria-label": "running a grpc kotlin service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a gRPC-Kotlin service`}</h3>
    <p>{`gRPC-kotlin support is enabled automatically by registering coroutine stub services in
`}<a parentName="p" {...{
        "href": "/docs/server-grpc"
      }}>{`the same way`}</a>{` as using gRPC-Java.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`import com.linecorp.armeria.server.Server
import com.linecorp.armeria.server.docs.DocService
import com.linecorp.armeria.server.grpc.GrpcService

class YourServiceImpl : YourServiceGrpcKt.YourServiceCoroutineImplBase() {
  ...
}

// Creates GrpcService with your gRPC stub generated by gRPC-Kotlin.
val grpcService =
  GrpcService
    .builder()
    // Add your gRPC-Kotlin stub to GrpcService
    .addService(YourServiceImpl())
    .enableUnframedRequests(true)
    .build()

// Creates Armeria Server for gRPC-Kotlin stub.
Server
  .builder()
  .http(httpPort)
  .https(httpsPort)
  .service(grpcService)
  // Add DocService for browsing the list of gRPC services and 
  // invoking a service operation from a web form.
  // See https://armeria.dev/docs/server-docservice for more information.
  .serviceUnder("/docs", DocService())
  .build()
`}</code></pre>
    <h3 {...{
      "id": "calling-a-grpc-kotlin-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#calling-a-grpc-kotlin-service",
        "aria-label": "calling a grpc kotlin service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calling a gRPC-Kotlin service`}</h3>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "type://GrpcClients:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/grpc/GrpcClients.html"
      }}>{`type://GrpcClients`}</a>{` to specify a coroutine stub that ends with `}<inlineCode parentName="p">{`CoroutineStub`}</inlineCode>{` when creating a
gRPC-Kotlin client.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val client =
  GrpcClients
    .builder("https://grpc.service.com")
    .intercept(yourInterceptors)
    .build(YourServiceGrpcKt.YourServiceCoroutineStub::class.java)
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`Please refer to `}<a parentName="p" {...{
          "href": "/docs/client-grpc"
        }}>{`Calling a gRPC service`}</a>{` for more information on gRPC clients.`}</p>
    </Tip>
    <h3 {...{
      "id": "customizing-coroutinecontext-for-grpc-kotlin-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#customizing-coroutinecontext-for-grpc-kotlin-services",
        "aria-label": "customizing coroutinecontext for grpc kotlin services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing `}<inlineCode parentName="h3">{`CoroutineContext`}</inlineCode>{` for gRPC-Kotlin services`}</h3>
    <p>{`When you use gRPC-Kotlin's suspend functions, the default `}<inlineCode parentName="p">{`CoroutineContext`}</inlineCode>{` is the combination of
`}<a parentName="p" {...{
        "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
      }}>{`type://ServiceRequestContext`}</a>{` and an event loop `}<inlineCode parentName="p">{`CoroutineDispatcher`}</inlineCode>{`. You can access
`}<a parentName="p" {...{
        "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
      }}>{`type://ServiceRequestContext`}</a>{` from the suspend functions with `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext#current():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#current()"
      }}>{`type://ServiceRequestContext#current()`}</a>{`,
similar to how you access it in annotated services.`}</p>
    <p>{`With Armeria's integration with gRPC-Kotlin, it's easy to set up a custom `}<inlineCode parentName="p">{`CoroutineContext`}</inlineCode>{` for your
gRPC-Kotlin service. You can create your own `}<inlineCode parentName="p">{`CoroutineContext`}</inlineCode>{` using `}<a parentName="p" {...{
        "href": "type://CoroutineContextProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/kotlin/CoroutineContextProvider.html"
      }}>{`type://CoroutineContextProvider`}</a>{`, and
add it to your service through Java SPI.`}</p>
    <p>{`Note that the `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
      }}>{`type://ServiceRequestContext`}</a>{` is still a part of the
`}<inlineCode parentName="p">{`CoroutineContext`}</inlineCode>{`, but the event loop `}<inlineCode parentName="p">{`CoroutineDispatcher`}</inlineCode>{` is disabled if the custom `}<inlineCode parentName="p">{`CoroutineContext`}</inlineCode>{`
is used.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`class CustomCoroutineContextProvider : CoroutineContextProvider {
    override fun provide(ctx: ServiceRequestContext): CoroutineContext {
      return ... // A custom CoroutineContext
    }
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`A custom `}<inlineCode parentName="p">{`CoroutineContextProvider`}</inlineCode>{` should be registered via Java SPI in
`}<inlineCode parentName="p">{`META-INF/services/com.linecorp.armeria.common.kotlin.CoroutineContextProvider`}</inlineCode>{`.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      